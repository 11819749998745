<template>
  <vue-simple-suggest
      v-model="query"
      placeholder="Search Courses..."
      display-attribute="name"
      value-attribute="id"
      :list="getCourseList"
      :min-length="3"
      :debounce="200"
      :max-suggestions="limit"
      :styles="autoCompleteStyle"
      @suggestion-click="selected"
      @request-start="onRequestStart"
      @request-done="onRequestDone"
      @request-failed="onRequestFailed"
      @focus="focus"
      @blur="blur"
  >
    <div slot="suggestion-item" slot-scope="{ suggestion }">
      <div class="row">
        <div class="col">
          <span v-html="suggestion.name"/>
          <small>
            {{ dateRange(suggestion.startDate, suggestion.endDate) }}
            {{ timeRange(suggestion.startTime, suggestion.endTime) }}
          </small>
          <br/>
          <span :class="'badge badge-' + suggestion.session.program_slug">
            {{ suggestion.session.program_slug }}
          </span>
          <strong><span v-html="suggestion.session.name"/></strong>
        </div>
        <div class="col col-2">
          <cs-button
              type="primary"
              iconLeft="view"
              @click.stop="view(suggestion)"
          >
            Go
          </cs-button>
        </div>
      </div>
    </div>
    <template
        slot="misc-item-above"
        slot-scope="{ suggestions, query }"
        class="bg-info-light"
    >
      <div class="misc-item">
        <span>You're searching for '{{ query }}'.</span>
      </div>

      <template v-if="suggestions.length > 0">
        <div class="misc-item">
          <span>{{ suggestions.length }} suggestions are shown...</span>
        </div>
        <hr/>
      </template>

      <div class="misc-item" v-else-if="!loading">
        <span>No results</span>
      </div>
    </template>
    <div
        class="misc-item"
        slot="misc-item-below"
        slot-scope="{}"
        v-if="loading"
    >
      <cs-loading>Loading...</cs-loading>
    </div>
  </vue-simple-suggest>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS
import { API } from '@/utilities/http-common'
import Program from '@/models/Program'
import { dateRange, timeRange } from '@/utilities/dateFormatters'
import { isEmpty } from '@/utilities/utilities'

export default {
  name: 'CourseSearch',
  components: {
    VueSimpleSuggest
  },
  props: {
    program: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      name: '',
      selectedProgram: this.program,
      query: '',
      prevQuery: '',
      courses: [],
      hasFocus: false,
      limit: 10,
      loading: false,
      autoCompleteStyle: {
        vueSimpleSuggest: 'position-relative',
        inputWrapper: 'input-group mb-3',
        defaultInput: 'form-control',
        suggestions: 'position-absolute list-group z-1000',
        suggestItem: 'list-group-item'
      }
    }
  },
  computed: {},
  methods: {
    timeRange,
    dateRange,
    focus (e) {
      this.$emit('focus', e)
    },
    blur (e) {
      this.$emit('blur', e)
    },
    programSlug (id) {
      const program = Program.find(id)
      console.log('CourseSearch.programSlug', id, program)
      if (!isEmpty(program)) {
        return program.slug
      } else {
        return ''
      }
    },
    getCourseList (q) {
      const data = {
        params: {
          name: this.query,
          limit: 10
        }
      }
      if (this.query === this.prevQuery) {
        return this.courses
      }
      return API.get('/courses/search', data)
        .then(response => {
          const data = response.data
          this.courses = data.courses
          this.prevQuery = this.query
          return data.courses
        })
        .catch(() => {

        })
    },
    gotFocus () {
      this.hasFocus = !this.hasFocus
      this.$emit('got-focus', this.hasFocus)
    },
    onRequestStart (value) {
      this.loading = true
    },
    onRequestDone (e) {
      this.loading = false
    },
    onRequestFailed (e) {
      this.loading = false
    },
    view (course) {
      this.goTo(course)
    },
    selected (course) {
      this.goTo(course)
    },
    goTo (course) {
      const session = course.session
      const program = session.program_slug
      const params = {
        course_id: course.id,
        course: course.slug,
        session_id: course.session_id,
        select: 'session'
      }
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(params)
      url.pathname = '/' + program + '/'
      for (const [key] of url.searchParams) {
        url.searchParams.delete(key)
      }
      for (const [key, value] of searchParams) {
        url.searchParams.set(key, value)
      }
      console.log('CourseSearch.goTo', url.href)
      window.location = url.href
    }
  }
}
</script>

<style scoped></style>
